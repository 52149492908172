@import '../variables.scss';

.footer {
    font-size: 0.8rem;
    color: #656565;
    background-color: #f1f1f1;
}

@media screen and ( max-width: $lg) {
    
    
}