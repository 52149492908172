@import '../variables.scss';

.root {
    position: fixed;
    z-index: 1110;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: none;
    border: none;
    overflow-x: auto;
    padding: 0;
}

.dialogOverlay {
    background-color: #000;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: -1;
    opacity: 0.6;
}

.container {
    max-width: 512px;
    width: 90%;
    
}

.consentRoot {
    max-height: 80vh;
    background-color: #fff;
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    box-shadow: rgb(0 0 0 / 16%) 0px 4px 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    color: $textColor;
    overflow-y: auto;  
}

.infoText {
    line-height: 1.3rem;
    font-size: 1rem;
}

.logo {
    width: auto;
    margin-bottom: 1rem;
    text-align: center;
}

.consentPreferences {
    max-height: 80vh;
    background-color: #fff;
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    box-shadow: rgb(0 0 0 / 16%) 0px 4px 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    color: $textColor;
    overflow-y: auto; 
    max-width: 512px;
    width: 90%; 
    position: relative;
    @media screen and (max-width: $sm) {
        max-height: 100%;
        height: 100%;
        border: none;
        width: 100%;
    };
}

.preferenceContainer {
    position: relative;
    min-height: 150px;
}

.categoryGroup {
    width: 100%;
    list-style-type: none;
    padding: 0;
    border-top: 1px solid $secondaryColor;
    border-bottom: 1px solid $secondaryColor;
    display: block;
    min-height: 250px;
    @media screen and (max-width: $sm) {
        border: none;
    }
}

.categoryItem {
    width: 35%;
    background: #f6f3f9;
    border-bottom: 1px solid #cac2d5;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0.7rem 0.7rem 0.7rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1rem;
    @media screen and (max-width: $sm) {
        width: 100%;
    };
}

.categoryItem.selected {
    background: $secondaryColor;
    color: #fff;
}

.categoryDescription {
    position: absolute;
    top: 0;
    right:0;
    width: 65%;
    line-height: 1.3rem;
    font-size: 1rem;
    padding: 0 1rem;
    display: block;
    @media screen and (max-width: $sm) {
        width: 100%;
        top: auto;
        margin-top: 1rem;
        position: relative;
        padding-bottom: 1rem;
        border-bottom: 1px solid $secondaryColor;
    };
}

.consentButton {
    display: inline-block;
    cursor: pointer;
    margin-top: 0.3rem;
    @media screen and (max-width: $sm) {
        margin-top: 0;
    }
}

.link { cursor:pointer;}

.optButtons {
    button {
        margin: 0.5rem;
    }
}