$sm: "576px";
$md: "768px";
$lg: "992px";
$xl: "1200px";
$primaryColor: #e33c25;
$secondaryColor: #23a5a1;
$bgPrimary: #ffecea;
$bgSecondary: #daebea;
$bgBasic: #f9f9f7; 
$linkColor: #007bff;
$textColor: #565656;
$blueColor: #244e7b;

