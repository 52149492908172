@import '../variables.scss';

.full_screen_msg {
    width:100%;
    height:100%;
    z-index:1000;
    font-size: 2rem;
    line-height: 3rem;
    opacity: 1;
    position: absolute;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
}

.fullPage {
    height: 100vh;
    margin:0;
    position: fixed;
    width:100%;
    height:100%;
    z-index:1000;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    top: 0;
    left:0;
    background-color: #f1f1f1;
    opacity: 0.8;
    color: #000;
}

@media screen and ( max-width: $sm) {
    .full_screen_msg {
        font-size: 1.4rem;
    }
}