@import '../variables.scss';
@import '../items.scss';

.cartSummary {
    color: #656565;
    display: flex;
    .productName {
        font-size: 0.9rem;
        line-height: 1.1rem;
        font-weight: bold;
        margin-bottom: 0;
    }
    .productInfo {
        font-size: 0.7rem;
        line-height: 1rem;
        margin-bottom: 0;
    }
    .price {
        color: $secondaryColor;
    }
    .currency {
        font-size: 0.7rem;
        padding-left: 0.5rem;
    }
    .image {
        max-width: 75px;
        display: block;
    }
}


