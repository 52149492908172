@import '../variables.scss';
@import '../items.scss';

.dropItem {
    max-width: 1140px;
    margin: 0 auto;
    a {
        color: #141414;
    }
}

.topic {
    @include categoryTitle;
    color: #565656;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
}

.logo {
    div {
        vertical-align: middle;
    } 
}

.userInit {
    border-radius: 50%;
    background-color: $secondaryColor;
    color: #fff;
    padding: 0.5rem;
}

.dropButton {
    button:hover, button:focus {
        text-decoration: none;
    }
}

.cartBadge {
    font-size: 0.6rem;
    background-color: $primaryColor;
    float: left;
    color: #fff;
}

.dropCart {
    button, button:hover, button:focus, button:active {
        color: #656565;
    }
    .showCart {
        color: #fff;
        background-color: $primaryColor;
        &:hover{
            background-color: $primaryColor;
            color: #fff;
        }
    }
}

.cartSize {
    font-size: 1.3rem;
    color: #656565;
}


