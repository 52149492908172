@import '../variables.scss';
@import '../items.scss';

.root {
    position: fixed;
    inset: 0px;
    z-index: 1200;
}
.back {
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: fixed;
    display:flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: -1;
}

.paperRoot {
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    box-shadow: rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
    z-index: 1200;
    position: fixed;
    top: 0px;
    outline: 0px;
    left: 0px;
}

.box {
    width: 400px;
    transform: none;
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    @media screen and (max-width: $sm) {
        width: 250px;
    }
}

.brand {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    & span {
        border-radius: 0.20rem;
        width: 100%;
    }
}

.divider {
    margin: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: #E5E8EC;
    border-bottom-width: thin;
    border-color: #EAEEF3;
}

ul.list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    & li {
        padding: 0 0.75rem;
        margin-top: 0.75rem;
        display: block;
        & button {
            background-color: #fff;
            border:none;
            color: #565656;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
        }

    }
}

.submenu {
    display: flex;
    width: 100%;
    height: auto;
    overflow: visible;
    -webkit-transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}