@import '../variables.scss';
@import '../items.scss';

.infoBox {
    @include boxWrapper;
}

.infoBox .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoBox .title {
    @include categoryTitle;
    font-weight: bold;
    color: #23a5a1;
    margin-bottom: 0.5rem;
}

.infoBox .item {
    font-size: 1rem;
    color: #000;
    line-height: 1.5rem;
    margin-bottom: 0.75rem;
    font-size: 1rem;
    @media screen and (max-width: $sm) {
        margin-bottom: 0.7rem;
        font-size: 1.1rem;
    };
}

.infoBox .label {
    font-size: 0.9rem;
    color: #656565;
}

.infoBox .edit {
    color: #007bff;
    font-size: 0.9rem;
    &:hover {
        cursor: pointer
    }
}

.fullModal {max-width: 100%;}
.borderBottom {border-bottom: 1px solid #dee2e6;}

.dash {
    .itemTitle a {
        line-height: 1.5rem;
        color: $blueColor;
        font-size: 1.1rem;
    }
    
    .itemInfo {
        line-height: 1.3rem;
        font-size: 0.95rem;
        @media screen and (max-width: $lg) {
            margin-bottom: 1rem;
            margin-top: 0.5rem;
        }
    }
    
    .itemDetails {
        color: $blueColor;
    }
}


.imgLink {
    cursor: pointer;
}