@import './variables.scss';

@mixin categoryTitle {
    font-size: 0.7rem;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: bold;
    letter-spacing: 0.1rem;
}

@mixin boxWrapper {
    font-size: 1rem;
    color: #656565;
    border: 1px solid #f1f1f1;
    border-radius: 0.1rem;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
     -moz-box-shadow: 0 0 5px rgba(0,0,0,0.3);
     -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.3);
     -o-box-shadow: 0 0 5px rgba(0,0,0,0.3);
     background-color: #fff;
}